import { Blip } from '@/components/Blip';
import { Link } from '@/components/Link';
import { StandaloneComponent } from '@/types/component';

export const HantStandaloneDirektBanner: StandaloneComponent = () => {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-headline-xs">DIREKTRAPPORTERING</span>

      <Link
        href="/direkt"
        options={{
          className: 'hover:no-underline no-underline',
        }}
        content={
          <div className="relative flex flex-wrap items-center gap-1 bg-gray-100 px-3 pb-2 pt-3 leading-none text-black no-underline text-headline-md before:absolute before:left-0 before:top-0 before:h-full before:w-1 before:bg-primary-700">
            <Blip options={{ className: 'mr-1 mb-1.25' }} />
            <span className="mr-1 text-red-700">Hänt Direkt</span>
            <span>- Senaste nytt</span>
            <span>från nöjesvärlden</span>
          </div>
        }
      />
    </div>
  );
};

import { tw } from '@/utils/tw';
import { default as BaseVideoReelsTheme } from 'base/components/VideoReels/theme';

const VideoReels = tw.theme({
  extend: BaseVideoReelsTheme,
  slots: {
    heading: 'font-bold uppercase',
  },
});

export default VideoReels;
